import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import './image.scss';

const Image = ({ gatsbyImageData, file, keyProp, url }) => {
  debugger;
  const imagePath = getImage(gatsbyImageData);
  return (
    <div className="runner-image-container" key={keyProp}>
      <GatsbyImage image={imagePath} alt={'some'} className="image" />
      <div className="runner-image-container__hover">
        <a href={url} target="_blank" rel="noopener noreferrer">
          Open Link
        </a>
      </div>
    </div>
  );
};

export default Image;
