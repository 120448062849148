import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useState } from 'react';

import Header from '../components/header/header';
import Image from '../components/image/image';
// import Search from '../components/search/search';
import 'styles/home.scss';

// markup
const IndexPage = () => {
  const {
    allEvents: { nodes: events },
  } = useStaticQuery(query);

  const [selectedEvents, setSelectedEvents] = useState(events);
  const [featuredToggler, setFeaturedToggler] = useState(true);

  const selectEvent = (event) => {
    if (event) {
      setSelectedEvents([event]);
      setFeaturedToggler(false);
    } else {
      setSelectedEvents(events);
      setFeaturedToggler(true);
    }
  };

  const renderFeatured = () => {
    return (
      <section className="featured-runners container">
        <div className="featured-runners-container event-runner">
          {events.map((event) => {
            return (
              <div className="event-container" key={event.id}>
                <div className="event-name">
                  <h1 className="event-header">{event.name}</h1>
                </div>
                <Image
                  {...event.image}
                  key={`event-image-${event.id}`}
                  url={event.url}
                />
              </div>
            );
          })}
          {/*d {featuredRunners.map(featuredRunner => {
            const image = featuredRunner?.images? featuredRunner.images[0] : null;
            if (image) {
              return (
                <Image
                  {...image}
                  keyProp={`featured-image-${featuredRunner.id}`}
                  key={`runner-image-${featuredRunner.id}`}
                />
              )
            }
            return null;
          })} */}
        </div>
      </section>
    );
  };

  const renderMainContent = () => {
    return (
      <section className="container">
        {selectedEvents.map((event, eventIndex) => {
          const { event: runners } = event;
          return (
            <div key={`event-${eventIndex}`} className="app-events">
              <h1 className="event-header">{event.name}</h1>
              {events.map((event) => {
                return (
                  <div className="event-container" key={event.id}>
                    <div className="event-name">
                      <h1 className="event-header">{event.name}</h1>
                    </div>
                    <Image
                      {...event.image}
                      key={`event-image-${event.id}`}
                      url={event.url}
                    />
                  </div>
                );
              })}
            </div>
          );
        })}
      </section>
    );
  };

  return (
    <section>
      <Helmet>
        <title>Home</title>
        <link rel="canonical" href="https://www.veeruphotography.com" />
      </Helmet>
      <Header />
      {/* <Search events={events} selectEvent={selectEvent} /> */}
      {featuredToggler ? renderFeatured() : renderMainContent()}
    </section>
  );
};

const query = graphql`
  {
    allEvents: allContentfulEvent {
      nodes {
        date
        name
        url
        marathonLocation
        id
        image {
          gatsbyImageData
          file {
            url
          }
        }
      }
    }
  }
`;

export default IndexPage;
