import React from 'react';
import "@fontsource/festive";

import './header.scss';

const Header = () => {
  return (
    <header className="app-header">
      <div className="app-header-content">
        <h1 className="logo">Veeru Photography</h1>
      </div>
    </header>
  );
};

export default Header;
